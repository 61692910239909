import React, { useState, useEffect, useRef } from "react";
import Laptop from "../assets/images/laptop.png";
import Toners from "../assets/images/hp_653AMagenta.webp";
import Mobile from "../assets/images/Iphone15Pro.png";
import Workspace from "../assets/images/workspace.png";
import { Link } from "react-router-dom";

const AdvertCategories = [
    {
        title: "Trending", 
        subtitle: "Devices",
        heading: "Laptops",
        buttonLabel: "Shop by Category",
        imageUrl: Laptop,
        url: "/laptops",
        description:
            "Discover the future of computing at our e-commerce store. Elevate your digital experience with trending laptops that blend innovation and style. Shop now for seamless productivity and performance.",
    },
    {
        title: "Get Best",
        subtitle: "Quality",
        heading: "Toners",
        buttonLabel: "Explore Now",
        imageUrl: Toners,
        url: "/toners",
        description:
            "Stay ahead with the latest toners in the market. Experience cutting-edge technology and sleek designs. Explore our new arrivals and upgrade your toners experience.",
    },
    {
        title: "Set Up",
        subtitle: "Unique",
        heading: "WorkSpace",
        buttonLabel: "Explore Now",
        imageUrl: Workspace,
        url: "/mobiles",
        description:
            "Transform your work space with our cutting-edge toners. Stay ahead of the curve with the latest technology and sleek designs. Explore our new arrivals and elevate your toners experience to new heights.",
    },
    {
        title: "Take",
        subtitle: "Breath Taking",
        heading: "Pictures",
        buttonLabel: "Explore Now",
        imageUrl: Mobile,
        url: "/workspace",
        description:
            "Transform your work space with our cutting-edge toners. Stay ahead of the curve with the latest technology and sleek designs. Explore our new arrivals and elevate your toners experience to new heights.",
    },
];

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const autoplayRef = useRef();

    useEffect(() => {
        startAutoplay();
        return () => {
            stopAutoplay();
        };
    });

    const startAutoplay = () => {
        stopAutoplay();
        autoplayRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === AdvertCategories.length - 1 ? 0 : prevIndex + 1
            );
        }, 1500); // Autoplay speed
    };

    const stopAutoplay = () => {
        if (autoplayRef.current) {
            clearInterval(autoplayRef.current);
        }
    };

    return (
        <div className="carousel-container relative">
            {AdvertCategories.map((category, index) => (
                <div
                    key={index}
                    className={`slide ${
                        index === currentIndex ? "active" : ""
                    } flex justify-center items-center`}
                    style={{
                        display: index === currentIndex ? "flex" : "none",
                    }}
                >
                    <div className="mx-2 sm:mx-1">
                        <div className="bg-blue-100 rounded-3xl items-center p-16 sm:p-6 gap-4  grid grid-cols-3 sm:grid-cols-2">
                            <div className="grid gap-6 sm:gap-2">
                                <p className="text-xl sm:text-xs">
                                    {category.title}
                                </p>
                                <p className="font-bold text-4xl sm:text-sm">
                                    {category.subtitle}
                                </p>
                                <h1 className="font-semibold text-8xl sm:text-sm">
                                    {category.heading}
                                </h1>
                                <Link
                                    to={category.url}
                                    className="rounded-full text-center p-4 bg-inmarknet-blue text-white w-1/2 sm:w-32 sm:text-xs sm:p-2 my-6"
                                >
                                    {category.buttonLabel}
                                </Link>
                            </div>
                            <div className="">
                                <img
                                    src={category.imageUrl}
                                    alt={category.heading}
                                    width="100%" // This will make the image responsive and occupy the full width of its parent container
                                    height="350" // This sets the default height; note that it's in pixels but without the "px" suffix
                                    className="object-contain object-center w-full h-[350px] sm:h-[150px]"
                                    onError={(e) =>
                                        (e.currentTarget.src =
                                            "path_to_fallback_image")
                                    }
                                    loading="lazy"
                                />
                            </div>
                            <div className="text-left sm:hidden">
                                <h1 className="font-bold my-4 text-2xl">
                                    Description
                                </h1>
                                <p>{category.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Carousel;
