import React, { memo } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { FaCartShopping } from "react-icons/fa6";

const ProductCard = memo(
    ({
        id,
        title,
        details,
        rating,
        tags,
        SKU,
        description,
        availability,
        dimensions,
        weight,
        thumbnails,
        mainImageURL,
    }) => {
        // Check if essential nested objects or properties are not defined
        if (
            !details ||
            !rating ||
            !availability ||
            !dimensions ||
            !thumbnails
        ) {
            return (
                <div>
                    <LoadingSpinner />{" "}
                </div>
            ); // Placeholder for loading state or missing data
        }

        // Encode each thumbnail URL
        const encodedThumbnails = thumbnails.map((thumbnail) => ({
            ...thumbnail,
            src: encodeURIComponent(thumbnail.src),
        }));

        return (
            <div className="sm:mb-3 mb-28 h-72 sm:h-60">
                <Link
                    to={`/product/${encodeURIComponent(
                        title
                    )}/description?image=${encodeURIComponent(
                        mainImageURL // Directly using mainImageURL here
                    )}&price=${encodeURIComponent(
                        details.price
                    )}&SKU=${encodeURIComponent(
                        SKU
                    )}&ratings=${encodeURIComponent(
                        rating.average
                    )}&reviewCount=${encodeURIComponent(
                        rating.reviewCount
                    )}&tags=${encodeURIComponent(
                        JSON.stringify(tags)
                    )}&inStock=${encodeURIComponent(
                        availability.inStock
                    )}&quantity=${encodeURIComponent(
                        availability.quantity
                    )}&backorder=${encodeURIComponent(
                        availability.backorder
                    )}&dimensions=${encodeURIComponent(
                        JSON.stringify(dimensions)
                    )}&weight=${encodeURIComponent(
                        weight
                    )}&thumbnails=${encodeURIComponent(
                        JSON.stringify(encodedThumbnails)
                    )}&description=${encodeURIComponent(description)}`}
                >
                    <div className="w-88 h-[29rem] sm:h-60 cursor-pointer bg-white border border-blue-300 shadow-black rounded-xl hover:shadow-xl">
                        <div className="h-56 sm:h-36 flex items-center">
                            <img
                                className="mx-auto h-[90%] object-center object-contain"
                                src={mainImageURL}
                                alt={title}
                                loading="lazy"
                            />
                        </div>
                        <div className="px-6 sm:px-2 grid h-48 sm:hidden">
                            <div className="w-full sm:w-full my-2 sm:my-1">
                                <p className="h-12 sm:h-8 font-bold sm:text-xs overflow-hidden text-ellipsis line-clamp-2">
                                    {title}
                                </p>
                            </div>
                            <div className="w-full sm:w-full">
                                <p className="max-h-[4em] sm:max-h-[2em] sm:text-xs text-sm leading-6 overflow-hidden text-ellipsis line-clamp-2">
                                    {description}
                                </p>
                            </div>
                            <p className=" text-inmarknet-blue my-3 sm:my-1">
                                Ksh. {details.price}
                            </p>
                            <button className="my-1 bg-inmarknet-blue text-white p-3 w-full flex items-center justify-center">
                                <FaCartShopping className="mx-4" />
                                Add To Cart
                            </button>
                        </div>
                        {/* Small Screen Card */}
                        <div className="sm:inline hidden px-2 py-6 h-[0rem]">
                            <div className="w-full sm:w-full my-2 sm:my-1">
                                <p className="h-12 sm:h-8 font-bold sm:text-xs overflow-hidden text-ellipsis line-clamp-2">
                                    {title}
                                </p>
                            </div>
                            <p className=" text-inmarknet-blue my-3 sm:my-1 text-sm">
                                Ksh. {details.price}
                            </p>
                            <button className="my-1 bg-inmarknet-blue text-white p-2 w-full flex items-center justify-center text-sm">
                                <FaCartShopping className="mx-4" />
                                Add To Cart
                            </button>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
);
export default ProductCard;
