import React from 'react'
import WhatsAppButton from './WhatsApp'
import ScrollToTop from './ScrollToTop'

export default function FloatingButtons() {
  return (
    <div className='grid fixed right-4 bottom-4 place-items-center justify-items-center'>
      <WhatsAppButton phoneNumber={+254725001567}/>
      <ScrollToTop/>
    </div>
  )
}
