import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion"; // Import Framer Motion
import { IoSearch } from "react-icons/io5";

export default function SearchBar() {
    const [searchQuery, setSearchQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchQuery.length > 2) {
            const fetchSuggestions = async () => {
                try {
                    const response = await axios.get(
                        `${
                            process.env.REACT_APP_LARAVEL_APP_API_URL
                        }/suggest?term=${encodeURIComponent(searchQuery)}`
                    );
                    setSuggestions(response.data);
                } catch (error) {
                    console.error("Failed to fetch suggestions", error);
                    setSuggestions([]);
                }
            };
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchQuery]);

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = () => {
        if (searchQuery.trim()) {
            navigate(
                `/search-results?query=${encodeURIComponent(searchQuery)}`
            );
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    const containerVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delayChildren: 0.1,
                staggerChildren: 0.1,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.3 },
        },
    };

    return (
        <div className="w-1/4 sm:w-4/5 relative">
            <input
                type="text"
                value={searchQuery}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                className="rounded-full text-sm bg-white p-3 px-6 w-full shadow-md border border-inmarknet-blue placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                placeholder="Search for a product"
            />
            <motion.div
                className="absolute overflow-x-hidden top-full mt-1 w-full bg-white shadow-lg max-h-60 overflow-auto z-10"
                variants={containerVariants}
                initial="hidden"
                animate={suggestions.length > 0 ? "visible" : "hidden"}
            >
                {suggestions.map((suggestion, index) => (
                    <motion.div
                        key={index}
                        className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() =>
                            navigate(
                                `/search-results?query=${encodeURIComponent(
                                    suggestion.title
                                )}`
                            )
                        }
                        variants={itemVariants}
                        whileHover={{ scale: 1.05 }}
                    >
                        <img
                            src={suggestion.image}
                            alt={suggestion.title}
                            className="w-10 h-10 object-cover mr-2"
                        />
                        {suggestion.title}
                    </motion.div>
                ))}
            </motion.div>
            <button
                onClick={handleSearch}
                type="button"
                className="rounded-full w-10 h-10 p-2 absolute top-0 right-0 m-1 border bg-inmarknet-blue flex items-center justify-center"
            >
                <IoSearch className="text-white" />
            </button>
        </div>
    );
}
