// src/App.js
import React from "react";
import AppRoutes from "./routes";
import { ToastContainer, toast } from "react-toastify";

function App() {
    const handleCopy = (e) => {
        e.preventDefault(); // Prevent the default copy action
        toast.info("Copying is not allowed.");
    };

    const handleDragStart = (e) => {
        e.preventDefault(); // Prevent the default drag action
        toast.info("Dragging and Copying info on this website is not allowed"); // Inform the user
    };

    const handleContextMenu = (e) => {
        if (e.target.tagName === "IMG") {
            // Check if the right-clicked element is an image
            e.preventDefault(); // Prevent the default context menu
            toast.info("Right-click functionality is disabled on images."); // Optionally inform the user
        }
    };

    return (
        <div
            onCopy={handleCopy}
            onDragStart={handleDragStart}
            onContextMenu={handleContextMenu}
        >
            <AppRoutes />
            <ToastContainer />
        </div>
    );
}

export default App;
