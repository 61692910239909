import React from "react";
import { motion } from "framer-motion";

export const AnimatedRoute = ({ children }) => {
    const pageTransitionVariants = {
        hidden: { opacity: 0, x: -200 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 200 },
    };

    return (
        <motion.div
            variants={pageTransitionVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ type: "linear" }}
        >
            {children}
        </motion.div>
    );
};
