import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom"; // Assuming SideMenuLink uses NavLink for navigation

const SideMenu = ({ isOpen, onClose }) => {
    const [activeMenu, setActiveMenu] = useState({ title: null, subLinks: [] });
    const [expandedMenu, setExpandedMenu] = useState(null);

    const controls = useAnimation();
    const navigate = useNavigate(); // Initialize navigate hook

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape" && isOpen) {
                onClose();
                setActiveMenu({ title: null, subLinks: [] }); // Reset active menu on close
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [isOpen, onClose]);

    useEffect(() => {
        controls.start(isOpen ? "open" : "closed");
    }, [isOpen, controls]);

    // Menu items definition
    const menuItems = [
        {
            title: "Toners",
            subLinks: ["EPSON", "HP", "CANON", "BROTHER", "KYOCERA"],
        },
        {
            title: "Cartridges",
            subLinks: ["HP", "EPSON", "CANON", "BROTHER", "KYOCERA"],
        },
        {
            title: "Printers",
            subLinks: ["EPSON", "HP", "CANON", "BROTHER", "KYOCERA"],
        },
        {
            title: "Scanners",
            subLinks: ["EPSON", "HP", "CANON", "BROTHER", "KYOCERA"],
        },
        {
            title: "Laptop",
            subLinks: [
                "MACBOOK",
                {
                    title: "LENOVO",
                    thirdLevelLinks: ["Brand New", "Refurbished"], // New third-level submenu
                },
                {
                    title: "DELL",
                    thirdLevelLinks: ["Brand New", "Refurbished"], // New third-level submenu
                },
                "ACER",
                "ASUS",
                {
                    title: "HP",
                    thirdLevelLinks: ["Brand New", "Refurbished"], // New third-level submenu
                },
            ],
        },
        {
            title: "Accessories",
            subLinks: [
                "Mouse",
                "Keyboard",
                "PC Charger",
                "Monitor Screen",
                "Hard Disk",
                "SSD",
                "Graphics Card",
                "Power Bank",
                "Headphones",
            ],
        },
        { title: "Repairs & Installation" },
        { title: "About Us" },
    ];

    // Handle menu item click: Updated for third-level menus
    const handleMenuClick = (item, level = 1) => {
        if (typeof item === "string") {
            navigate(`/${item.toLowerCase().replace(/\s+/g, "-")}`);
            onClose();
        } else if (level === 1 && item.subLinks) {
            // Toggle the active menu for first-level items
            if (expandedMenu !== item.title) {
                setActiveMenu({
                    title: item.title,
                    subLinks: item.subLinks,
                    thirdLevelLinks: [],
                });
                setExpandedMenu(item.title);
            } else {
                // Collapse if the same menu is clicked again
                setExpandedMenu(null);
                setActiveMenu({ title: null, subLinks: [] }); // Reset active menu
            }
        } else if (level === 2 && item.thirdLevelLinks) {
            // Set active menu and prepare for third-level links
            setActiveMenu((current) => ({
                ...current,
                thirdLevelLinks: item.thirdLevelLinks,
                thirdLevelTitle: item.title,
            }));
            setExpandedMenu(item.title); // Expand this submenu
        } else if (item.title && !item.thirdLevelLinks) {
            navigate(`/${item.title.toLowerCase().replace(/\s+/g, "-")}`);
            onClose();
        }
    };

    // Adjust rendering logic in return statement for third-level menu

    const slideInVariants = {
        hidden: { x: "-100%" },
        visible: {
            x: 0,
            transition: { type: "spring", stiffness: 300, damping: 30 },
        },
        exit: { x: "-100%", transition: { duration: 0.2 } },
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={slideInVariants}
                    className="fixed top-0 left-0 h-screen w-4/5 bg-white shadow-lg z-50 overflow-y-scroll"
                >
                    <button
                        onClick={() => {
                            if (activeMenu.title) {
                                // If in a submenu, go back to the main menu
                                setActiveMenu({ title: null, subLinks: [] });
                            } else {
                                // If in the main menu, close the side menu
                                onClose();
                            }
                        }}
                        className="p-4"
                    >
                        {activeMenu.title ? (
                            <box-icon
                                name="left-arrow-alt"
                                size="md"
                            ></box-icon> // For submenu
                        ) : (
                            <box-icon
                                type="solid"
                                name="x-circle"
                                size="md"
                            ></box-icon> // For main menu
                        )}
                    </button>
                    <AnimatePresence>
                        {activeMenu.title === null ? (
                            <motion.nav
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={slideInVariants}
                                className="px-5"
                            >
                                {menuItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className="cursor-pointer p-2"
                                    >
                                        <div
                                            onClick={() =>
                                                handleMenuClick(item)
                                            }
                                            className="flex justify-between items-center w-full"
                                        >
                                            {item.title}
                                            {item.subLinks &&
                                                item.subLinks.length > 0 && (
                                                    <box-icon
                                                        type="solid"
                                                        name="chevron-right-circle"
                                                    ></box-icon>
                                                )}
                                        </div>
                                        {activeMenu.title === item.title && (
                                            <div>
                                                {item.subLinks.map(
                                                    (subLink, subIndex) => (
                                                        <NavLink
                                                            key={subIndex}
                                                            to={`/${item.title
                                                                .toLowerCase()
                                                                .replace(
                                                                    /\s+/g,
                                                                    "-"
                                                                )}/${subLink
                                                                .toLowerCase()
                                                                .replace(
                                                                    /\s+/g,
                                                                    "-"
                                                                )}`}
                                                            className="block p-2"
                                                        >
                                                            {subLink}
                                                        </NavLink>
                                                    )
                                                )}
                                            </div>
                                        )}
                                        <hr className="my-3" />
                                    </div>
                                ))}
                            </motion.nav>
                        ) : (
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={slideInVariants}
                                className="submenu-container px-5"
                            >
                                {activeMenu.subLinks.map((subLink, index) => {
                                    if (typeof subLink === "string") {
                                        return (
                                            <div key={index} className="p-2">
                                                <NavLink
                                                    to={`/${activeMenu.title
                                                        .toLowerCase()
                                                        .replace(
                                                            /\s+/g,
                                                            "-"
                                                        )}/${subLink
                                                        .toLowerCase()
                                                        .replace(/\s+/g, "-")}`}
                                                >
                                                    {subLink}
                                                </NavLink>
                                                <hr className="my-2" />
                                            </div>
                                        );
                                    } else if (
                                        typeof subLink === "object" &&
                                        subLink.thirdLevelLinks
                                    ) {
                                        return (
                                            <div
                                                key={index}
                                                className="p-2 grid gap-y-4"
                                            >
                                                <div
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            subLink,
                                                            2
                                                        )
                                                    }
                                                >
                                                    {subLink.title}
                                                </div>
                                                {activeMenu.thirdLevelTitle ===
                                                    subLink.title &&
                                                    activeMenu.thirdLevelLinks && (
                                                        <div className="submenu-container px-5">
                                                            {activeMenu.thirdLevelLinks.map(
                                                                (
                                                                    thirdLink,
                                                                    thirdIndex
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            thirdIndex
                                                                        }
                                                                        className="p-2"
                                                                    >
                                                                        <NavLink
                                                                            to={`/${activeMenu.thirdLevelTitle
                                                                                .toLowerCase()
                                                                                .replace(
                                                                                    /\s+/g,
                                                                                    "-"
                                                                                )}/${thirdLink
                                                                                .toLowerCase()
                                                                                .replace(
                                                                                    /\s+/g,
                                                                                    "-"
                                                                                )}`}
                                                                            className="block p-2"
                                                                            onClick={() => {
                                                                                handleMenuClick(
                                                                                    thirdLink
                                                                                ); // Handle third-level link navigation
                                                                            }}
                                                                        >
                                                                            {
                                                                                thirdLink
                                                                            }
                                                                        </NavLink>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                <hr className="my-2" />
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default SideMenu;
