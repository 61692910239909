import React from "react";
import "rc-slider/assets/index.css";
import TopNavigationBar from "../../../components/TopNavigationBar";
import Courasel from "../../../components/Courasel";
import Sort from "../../../components/Sort";
import { Helmet } from "react-helmet";
import Footer from "../../../components/Footer";
import FetchCategoryBrandCondition from "../../../services/FetchCategoryBrandCondition";
import FloatingButtons from "../../../components/FloatingButtons";

const BrandNewLenovoLaptops = () => {
    return (
        <div className="overflow-x-hidden h-full">
            <Helmet>
                <title>Inmarknet Laptops New Lenovo</title>
            </Helmet>
            <TopNavigationBar />

            <div className="mx-16 sm:mx-4 my-6">
                <Courasel />
            </div>

            {/* Sort Section */}
            <div className="bg-white">
                <div className="flex justify-between items-center pt-8 my-4 px-16 sm:px-4">
                    <h1 className="text-3xl sm:text-sm font-bold">
                        Categories
                    </h1>
                    <Sort />
                </div>
            </div>

            <div className="px-4 py-8">
                <FetchCategoryBrandCondition
                    category_id={1}
                    brand_id={10}
                    condition="new"
                />
            </div>
            <Footer />
            <FloatingButtons/>
        </div>
    );
};

export default BrandNewLenovoLaptops;
