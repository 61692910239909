import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import TopNavigationBar from "../../components/TopNavigationBar";
import StarRating from "../../components/StarRating";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import FloatingButtons from "../../components/FloatingButtons";

export default function ProductDescription() {
  const { title } = useParams();
  const location = useLocation();

  const thumbnailsParam = new URLSearchParams(location.search).get(
    "thumbnails"
  );
  const thumbnails =
    thumbnailsParam && thumbnailsParam.length
      ? JSON.parse(decodeURIComponent(thumbnailsParam))
      : [];

  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");
  const price = new URLSearchParams(location.search).get("price");
  const ratings =
    parseFloat(new URLSearchParams(location.search).get("ratings")) || 0;
  const numReviews =
    parseInt(new URLSearchParams(location.search).get("numReviews")) || 0;
  const [keySpecs, setKeySpecs] = useState([]);
  const [allSpecs, setAllSpecs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(image);
  const [activeTab, setActiveTab] = useState("description");

  useEffect(() => {
    const keySpecsParam = new URLSearchParams(location.search).get("keyspecs");
    if (keySpecsParam) {
      try {
        const decodedKeySpecs = JSON.parse(decodeURIComponent(keySpecsParam));
        setKeySpecs(decodedKeySpecs);
      } catch (error) {
        console.error("Error parsing key specifications:", error);
        setKeySpecs([]); // Set empty array in case of an error
      } finally {
        setLoading(false);
      }
    } else {
      setKeySpecs([]); // Set empty array if key specs are not provided
      setLoading(false);
    }

    const allSpecsParam = new URLSearchParams(location.search).get("allspecs");
    if (allSpecsParam) {
      try {
        const decodedAllSpecs = JSON.parse(decodeURIComponent(allSpecsParam));
        setAllSpecs(decodedAllSpecs);
      } catch (error) {
        console.error("Error parsing all specifications:", error);
        setAllSpecs([]); // Set empty array in case of an error
      }
    } else {
      setAllSpecs([]); // Set empty array if allspecs are not provided
    }
  }, [location.search]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const renderKeySpecifications = () => {
    if (loading) {
      return (
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={50}
            color={"#123abc"}
            loading={loading}
          />
        </div>
      );
    } else if (keySpecs.length === 0 && allSpecs.length === 0) {
      return <p>No specifications available</p>;
    } else {
      return (
        <div>
          <div className="my-6 w-full grid grid-cols-2 gap-4">
            {keySpecs.map((spec, index) => (
              <React.Fragment key={index}>
                <p className="font-bold">{spec.title}</p>
                <p>{spec.description}</p>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
  };

  const renderAllSpecifications = () => {
    if (loading) {
      return (
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={50}
            color={"#123abc"}
            loading={loading}
          />
        </div>
      );
    } else if (keySpecs.length === 0 && allSpecs.length === 0) {
      return <p>No specifications available</p>;
    } else {
      return (
        <div>
          <div className="grid grid-cols-1 gap-2 my-6 w-full">
            {allSpecs.map((spec, index) => (
              <React.Fragment key={index}>
                <p className="font-bold">{spec.title}</p>
                <p>{spec.description}</p>
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
  };

  const handleThumbnailHover = (thumbnailSrc) => {
    setSelectedImage(thumbnailSrc);
  };

  const renderButtons = () => {
    return (
      <div className="flex justify-between w-3/5 sm:w-full gap-3">
        <button className="p-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Buy Now
        </button>
        <button className="p-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          Add to Cart
        </button>
      </div>
    );
  };

  const hasThumbnails = thumbnails.length > 0;
  const imageColSpan = hasThumbnails ? "col-span-5" : "col-span-6"; // Adjust the span based on the availability of thumbnails

  return (
    <div>
      <Helmet>
        <title>{`Inmarknet - ${title}`}</title>
      </Helmet>
      <TopNavigationBar />
      <div className="mx-16 sm:mx-3 mt-16">
        <div
          className={`grid grid-cols-11 sm:grid-cols-5 gap-4 ${
            !hasThumbnails ? "sm:grid-cols-4" : ""
          }`}
        >
          {hasThumbnails && (
            <div className="grid">
              {thumbnails.map((thumbnail, index) => (
                <motion.div
                  key={index}
                  className="border border-gray-400 w-24 h-20 sm:w-16 sm:h-16 rounded-md p-1 cursor-pointer flex items-center justify-center"
                  whileHover={{ scale: 1.1 }}
                  onMouseEnter={() => handleThumbnailHover(thumbnail.src)}
                >
                  <img
                    src={decodeURIComponent(thumbnail.src)}
                    alt={thumbnail.alt}
                    className="object-cover"
                  />
                </motion.div>
              ))}
            </div>
          )}

          <div
            className={`${imageColSpan} sm:col-span-4 w-full bg-white shadow-lg rounded-md flex items-center justify-center`}
          >
            <img
              src={decodeURIComponent(selectedImage)}
              alt={title}
              className="max-w-full max-h-full"
            />
          </div>
          <div className="col-span-5 flex flex-col">
            <h2 className="text-3xl sm:text-lg sm:my-3 mb-2">{title}</h2>
            <StarRating rating={ratings} reviews={numReviews} />
            <p className="text-2xl font-bold mt-4">{price}</p>
            {renderKeySpecifications()}
            {renderButtons()}
          </div>
        </div>
        <div className="my-12">
          <div className="flex mx-auto gap-4 items-center justify-center">
            <h1
              className={`text-xl sm:text-lg cursor-pointer ${
                activeTab === "description"
                  ? "text-blue-600 border-b-2 pb-2 border-blue-600"
                  : ""
              }`}
              onClick={() => setActiveTab("description")}
            >
              Description
            </h1>
            <h1
              className={`text-xl sm:text-lg cursor-pointer ${
                activeTab === "specifications"
                  ? "text-blue-600 border-b-2 pb-2 border-blue-600"
                  : ""
              }`}
              onClick={() => setActiveTab("specifications")}
            >
              Specifications
            </h1>
          </div>
          <div className="border rounded-lg my-8 p-8">
            {activeTab === "description" && (
              <div>
                <h2 className="text-3xl sm:text-lg mb-2">{title}</h2>
                <p>{description}</p>
              </div>
            )}
            {activeTab === "specifications" && renderAllSpecifications()}
          </div>
        </div>
      </div>
      <FloatingButtons/>
    </div>
  );
}
