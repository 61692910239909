import React from "react";

const NotFoundPage = () => {
    return (
        <div className="grid place-items-center place-content-center text-center h-screen text-3xl sm:text-xl">
            <div>
                <h1>404 - Not Found!</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
                <p>
                    You can always go back to the <a href="/" className="text-inmarknet-blue">homepage</a>.
                </p>
            </div>
        </div>
    );
};

export default NotFoundPage;
