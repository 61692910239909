import React from "react";
import Inmarknet from "../assets/images/Inmarknet.svg";
import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import { NavLink } from "react-router-dom";
import BrothersPrinter from "../assets/images/brothers_printer.png";

export default function Footer() {
    const currentYear = new Date().getFullYear(); // Get the current year

    return (
        <div className="relative max-h-max mt-60 sm:mt-0">
            <div className="bg-gray-50 rounded-sm shadow-2xl sm:shadow-2xl justify-between items-center flex p-10 w-4/5 mx-auto absolute sm:relative sm:w-full sm:top-0 left-0 right-0 -top-28">
                <div className="text-black w-full">
                    <h1>Subscribe To Our Newsletter</h1>
                    <div className="relative w-2/5 sm:w-full">
                        <input
                            type="text"
                            name="newsletter"
                            id="newsletter"
                            className="border border-black bg-transparent p-3 my-4 w-full pr-10 rounded-md"
                        />
                        <button className="bg-inmarknet-blue p-2 rounded-md absolute top-1/2 transform -translate-y-1/2 right-3">
                            <IoMdSend className="text-white text-xl" />
                        </button>
                    </div>
                </div>
                <div className="">
                    <img
                        src={BrothersPrinter}
                        alt={BrothersPrinter}
                        className="h-64 absolute -top-20 right-0 sm:hidden"
                    />
                </div>
            </div>
            <div className="grid bg-inmarknet-blue text-white px-16 py-24 sm:px-4 sm:py-8">
                <div className="grid grid-cols-11 gap-4 justify-items-center sm:justify-items-start">
                    <div className="col-span-3 sm:col-span-11">
                        <h1 className="font-bold mb-6 text-2xl">About Us</h1>
                        <p className="text-justify text-sm">
                            INMARK NET COMPUTERS specializes in offering a wide
                            range of computing solutions, including high-quality
                            computers, printers, toners, and related
                            accessories. With a focus on delivering global
                            quality through local craftsmanship, we ensures that
                            every product meets the highest standards of
                            performance and reliability. Catering to both home
                            and office spaces, the company provides
                            customization options to meet specific needs,
                            alongside expert guidance on product selection and
                            setup.
                        </p>
                    </div>
                    <div className="col-span-2 sm:col-span-6">
                        <ul className="space-y-4 grid">
                            <NavLink
                                to="/"
                                className="hover:underline ease-linear duration-75"
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to="/repairs-&-installation"
                                className="hover:underline ease-linear duration-75"
                            >
                                Repairs & Installation
                            </NavLink>
                            <NavLink
                                to="/about-us"
                                className="hover:underline ease-linear duration-75"
                            >
                                About Us
                            </NavLink>
                        </ul>
                    </div>
                    <div className="col-span-2 sm:col-span-5">
                        <ul className="space-y-4 grid">
                            <NavLink
                                to="/toners"
                                className="hover:underline ease-linear duration-75"
                            >
                                Toners
                            </NavLink>
                            <NavLink
                                to="/cartridges"
                                className="hover:underline ease-linear duration-75"
                            >
                                Cartridges
                            </NavLink>
                            <NavLink
                                to="/laptops"
                                className="hover:underline ease-linear duration-75"
                            >
                                Laptops
                            </NavLink>
                            <NavLink
                                to="/printers"
                                className="hover:underline ease-linear duration-75"
                            >
                                Printers
                            </NavLink>
                        </ul>
                    </div>
                    <div className="col-span-2 sm:col-span-5 text-left">
                        <ul className="space-y-4 grid">
                            <NavLink
                                to="/scanners"
                                className="hover:underline ease-linear duration-75"
                            >
                                Scanners
                            </NavLink>
                            <NavLink
                                to="/smartphones"
                                className="hover:underline ease-linear duration-75"
                            >
                                Smartphones
                            </NavLink>
                            <NavLink
                                to="/desktops"
                                className="hover:underline ease-linear duration-75"
                            >
                                Desktop
                            </NavLink>
                            <NavLink
                                to="/accessories"
                                className="hover:underline ease-linear duration-75"
                            >
                                Accessories
                            </NavLink>
                        </ul>
                    </div>
                    <div className="col-span-2 sm:col-span-11 sm:my-4 text-left">
                        <h1 className="mb-6 font-bold text-2xl">Socials</h1>
                        <ul className="flex gap-4">
                            <FaFacebookF />
                            <FaInstagram />
                            <FaTiktok />
                            <FaTwitter />
                        </ul>
                    </div>
                </div>
                <div className="my-16 sm:my-10">
                    <img src={Inmarknet} alt="Inmarkent Text" />
                </div>
                {/* Updated section with dynamic year */}
                <div className="text-center mt-8 text-sm">
                    <p>
                        Copyright ©{currentYear}. All Rights Reserved. —
                        Designed & Built by Brandxela Solutions
                    </p>
                    <p>
                        <a
                            href="/terms-and-conditions"
                            className="hover:underline"
                        >
                            Terms & Conditions
                        </a>{" "}
                        |
                        <a href="/privacy-policy" className="hover:underline">
                            Privacy Policy
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
