import React from "react";
import WhatsAppIcon from "../../src/assets/images/whatsapp.png";

const WhatsAppButton = ({ phoneNumber, message = "How can I help you?" }) => {
    const handleClick = () => {
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        setTimeout(() => {
            window.open(whatsappUrl, "_blank");
        }, 500); // Delay of 500 milliseconds
    };

    return (
        <button
            onClick={handleClick}
            className="p-2 bg-white shadow-md shadow-gray-300 rounded-full"
            style={{ width: "64px", height: "64px" }} // Ensure the button is perfectly circular
        >
            <img src={WhatsAppIcon} alt="WhatsApp" className="w-full h-full" />
        </button>
    );
};

export default WhatsAppButton;
