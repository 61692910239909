import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to import axios
import LoadingSpinner from "../components/LoadingSpinner";
import ProductCard from "../components/ProductCard";
import Pagination from "../components/Paginations";

export default function FetchCategoryBrandCondition({ category_id, brand_id,condition }) {
    const [isTopBarSticky, setTopBarSticky] = useState(true);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const placeholderImageURL = "Image Not Loaded"; // Replace with your actual placeholder image URL
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const threshold = 100;
            setTopBarSticky(scrollPosition > threshold);
        };

        const fetchproducts = async (page = 1) => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products?category_id=${category_id}&brand_id=${brand_id}&condition=${condition}&page=${page}`
                );
                const processedproducts = response.data.data.map((product) => {
                    const mainImageURL =
                        product.images && product.images.length > 0
                            ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${product.images[0].url}`
                            : placeholderImageURL;

                    const thumbnails =
                        product.images?.slice(1).map((img) => ({
                            ...img,
                            src: `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${img.url}`,
                        })) || [];
                    return { ...product, mainImageURL, thumbnails };
                });

                setProducts(processedproducts);
                setTotalPages(response.data.last_page); // Update totalPages based on the API response
                console.log("Fetched Products:", processedproducts); // Log the processed products
            } catch (error) {
                console.error("Failed to fetch products:", error);
            } finally {
                setIsLoading(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        fetchproducts(currentPage); // Fetch products for the current page

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [brand_id, category_id, condition, currentPage]); // Depend on currentPage so it refetches when page changes

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const threshold = 100;
            setTopBarSticky(scrollPosition > threshold);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div
            className="w-full col-span-7 grid grid-cols-4 sm:grid-cols-2 sm:col-span-9 gap-y-20 sm:gap-y-12 gap-x-3 relative"
            style={{ marginTop: isTopBarSticky ? "10px" : "0" }}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : products.length > 0 ? (
                products.map((product) => (
                    <ProductCard
                        key={product.id}
                        id={product.id}
                        title={product.title}
                        details={{
                            price: product.price,
                            description: product.description,
                        }}
                        rating={{
                            average: "4.5", // Example static data
                            reviewCount: "10", // Example static data
                        }}
                        tags={["Tag1", "Tag2"]} // Example tags, adjust as necessary
                        SKU="SKU123" // Example SKU, adjust as necessary
                        description={product.description}
                        availability={{
                            // Check if `product.status` and `product.status.name` exist before trying to access `name`
                            inStock:
                                product.status &&
                                product.status.name === "In Stock",
                            quantity: 100, // Example quantity, adjust as necessary
                            backorder: false, // Example backorder status
                        }}
                        dimensions={{
                            width: "10cm", // Example dimensions, adjust as necessary
                            height: "5cm", // Example dimensions
                            depth: "2cm", // Example dimensions
                        }}
                        weight="0.5kg" // Example weight, adjust as necessary
                        thumbnails={product.thumbnails}
                        mainImageURL={product.mainImageURL}
                    />
                ))
            ) : (
                <div className="h-[80vh] p-10 justify-center items-center flex w-full border border-blue-300 col-span-7 rounded-xl text-inmarknet-blue">
                    No products found in this category.
                </div>
            )}
            {/* Pagination - Now outside the products grid but within the content container */}
            <div
                className="w-full flex justify-center mt-6"
                style={{ gridColumn: "1 / -1" }}
            >
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}
