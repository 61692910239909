import "./index.css";
import "./App.css"
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { hydrate, render } from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { AuthProvider } from "./web/auth/AuthProvider";

const Apps = (
    <React.StrictMode>
        <Router>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Router>
    </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Apps, rootElement);
} else {
  render(Apps, rootElement);
}
