import React from "react";

export default function LoadingSpinner() {
    return (
        <div className="absolute bg-white top-0 left-0 right-0 z-50 h-full flex justify-center items-center">
            <div class="container">
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
                <div class="slice"></div>
            </div>
        </div>
    );
}
