import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ProductCard from "./ProductCard";
import Pagination from "./Paginations";
import TopNavigationBar from "./TopNavigationBar";
import Footer from "./Footer";
import FloatingButtons from "./FloatingButtons";

export default function SearchResultsPage() {
    const [searchParams] = useSearchParams();
    const query = searchParams.get("query");
    const [results, setResults] = useState([]); // Default to an empty array
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!query) return; // Only fetch if there's a query

            setLoading(true);
            setError(null);

            try {
                // Include the current page in the API request URL
                const response = await fetch(
                    `${
                        process.env.REACT_APP_LARAVEL_APP_API_URL
                    }/products/search?query=${encodeURIComponent(
                        query
                    )}&page=${currentPage}`
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                if (data && data.data) {
                    setResults(data.data);
                    setTotalPages(data.last_page);
                } else {
                    setResults([]);
                    setTotalPages(0);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Failed to fetch results");
                setResults([]);
                setTotalPages(0);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [query, currentPage]); // Dependency on query and currentPage

    return (
        <div>
            <TopNavigationBar />
            <div className="w-full px-16 sm:px-4 my-10 grid grid-cols-4 sm:grid-cols-2 sm:col-span-9 gap-3">
                {loading && <div>Loading...</div>}
                {error && <div className="text-red-500">{error}</div>}
                {!loading &&
                    results.length > 0 &&
                    results.map((product) => (
                        <ProductCard
                            key={product.id}
                            id={product.id}
                            title={product.title}
                            details={{
                                price: product.price,
                                description: product.description,
                            }}
                            rating={{
                                average: "4.5", // Example static data
                                reviewCount: "10", // Example static data
                            }}
                            tags={["Tag1", "Tag2"]} // Example tags, adjust as necessary
                            SKU="SKU123" // Example SKU, adjust as necessary
                            description={product.description}
                            availability={{
                                inStock:
                                    product.status &&
                                    product.status.name === "In Stock",
                                quantity: 100, // Example quantity, adjust as necessary
                                backorder: false, // Example backorder status
                            }}
                            dimensions={{
                                width: "10cm", // Example dimensions, adjust as necessary
                                height: "5cm", // Example dimensions
                                depth: "2cm", // Example dimensions
                            }}
                            weight="0.5kg" // Example weight, adjust as necessary
                            thumbnails={
                                product.images && product.images.length > 0
                                    ? product.images.slice(1).map((img) => ({
                                          ...img,
                                          src: `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${img.url}`,
                                      }))
                                    : []
                            } // Safely map thumbnails if available
                            mainImageURL={
                                product.images && product.images.length > 0
                                    ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${product.images[0].url}`
                                    : "path/to/default/image.jpg"
                            } // Provide a default image if none are available
                        />
                    ))}

                {!loading && results.length === 0 && !error && (
                    <div>No results found.</div>
                )}
                <div
                    className="w-full flex justify-center mt-6"
                    style={{ gridColumn: "1 / -1" }}
                >
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
            <Footer />
            <FloatingButtons />
        </div>
    );
}
