import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import WebLogo from "../assets/images/logo.png";
import { MdMenu } from "react-icons/md";
import SearchBar from "./SearchBar";
import SideMenu from "./SideMenu";

export default function TopNavigationBar({backgroundColor='bg-white', shadow='shadow-lg ', textColor='text-black'}) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function preloadHomePage() {
        import("../web/homepage/HomePage");
    }

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const categories = [
        "Laptops",
        "Toners",
        "Cartridges",
        "Printers",
        "Scanners",
        "Smartphones",
        "Desktops",
        "Accessories",
    ];


    return (
        <div className={`sticky top-0 z-40 ${backgroundColor}`}>
            <div className={`${shadow} h-24 flex items-center justify-center sm:justify-between gap-14 sm:gap-6 sm:px-6 ${textColor} `}>
                <NavLink to="/" className="hover:text-inmarknet-blue">
                    <img src={WebLogo} alt="WebLogo" className="h-16 sm:h-10" />
                </NavLink>
                {isSmallScreen ? (
                    <>
                        <SideMenu
                            isOpen={isSideMenuOpen}
                            onClose={() => setIsSideMenuOpen(false)}
                        />
                        <div className="flex items-center gap-4">
                            <SearchBar />
                            <button
                                type="button"
                                className="rounded-full w-10 h-10 p-2 bg-inmarknet-blue flex items-center justify-center"
                                onClick={() =>
                                    setIsSideMenuOpen(!isSideMenuOpen)
                                }
                            >
                                <MdMenu className="text-2xl text-white" />
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-inmarknet-blue active"
                                    : "hover:text-inmarknet-blue"
                            }
                            onMouseOver={preloadHomePage}
                        >
                            Shop
                        </NavLink>
                        <NavLink
                            to="/repairs-&-installation"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-inmarknet-blue active"
                                    : "hover:text-inmarknet-blue"
                            }
                        >
                            Repairs & Installation
                        </NavLink>
                        <NavLink
                            to="/about-us"
                            className={({ isActive }) =>
                                isActive
                                    ? "text-inmarknet-blue active"
                                    : "hover:text-inmarknet-blue"
                            }
                        >
                            About Us
                        </NavLink>

                        <div className="relative">
                            <button
                                onMouseEnter={() => setDropdownOpen(true)}
                                onMouseLeave={() => setDropdownOpen(false)}
                                onFocus={() => setDropdownOpen(true)}
                                onBlur={() => setDropdownOpen(false)}
                                className="hover:text-inmarknet-blue focus:outline-none"
                            >
                                All Categories
                                <motion.span
                                    initial={false}
                                    animate={{
                                        rotate: isDropdownOpen ? 180 : 0,
                                    }}
                                >
                                    <box-icon
                                        type="solid"
                                        name="chevron-down"
                                        color="black"
                                    ></box-icon>
                                </motion.span>
                            </button>
                            <AnimatePresence>
                                {isDropdownOpen && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        onMouseEnter={() =>
                                            setDropdownOpen(true)
                                        }
                                        onMouseLeave={() =>
                                            setDropdownOpen(false)
                                        }
                                        className="absolute z-10 mt-2 w-48 bg-white shadow-lg rounded-md"
                                    >
                                        {categories.map((category, index) => (
                                            <Link
                                                to={`/${category.toLowerCase()}`}
                                                key={index}
                                            >
                                                <motion.div
                                                    className="p-2 py-3 hover:bg-blue-300 hover:text-white cursor-pointer rounded-md"
                                                    whileHover={{ scale: 1.05 }}
                                                >
                                                    {category}
                                                </motion.div>
                                            </Link>
                                        ))}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>

                        <SearchBar />
                        <div className="flex flex-row gap-2">
                            <NavLink
                                to="/login"
                                className="w-40 text-white bg-inmarknet-blue px-4 py-2 text-center rounded-full"
                            >
                                Login
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
