import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineSetting, AiOutlineUser, AiOutlineShoppingCart } from "react-icons/ai";
import { BiSolidHelpCircle } from "react-icons/bi";
import SignOutButton from './SignOutButton';

export default function AvatarProfile({ name, title }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Variants for the dropdown container
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { when: "beforeChildren", staggerChildren: 0.1 } },
  };

  // Variants for individual menu items
  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  // Menu items without the SignOutButton
  const menuItems = [
    { href: "#", text: "Account Settings", Icon: AiOutlineSetting },
    { href: "#", text: "Profile", Icon: AiOutlineUser },
    { href: "#", text: "Orders", Icon: AiOutlineShoppingCart },
    { href: "#", text: "Help", Icon: BiSolidHelpCircle },
  ];

  // Extract the first letter of the name for the avatar
  const avatarLetter = name ? name[0].toUpperCase() : '';

  return (
    <div className="relative">
      <button className="flex items-center space-x-2" onClick={toggleDropdown}>
        {/* Dynamic avatar with the first letter */}
        <div className="h-8 w-8 rounded-full bg-inmarknet-blue flex items-center justify-center text-sm text-white font-bold">
          {avatarLetter}
        </div>
        <div className="flex flex-col items-start">
          <span className="text-sm text-white font-semibold">{name}</span>
          <span className="text-xs text-white">{title}</span>
        </div>
      </button>
      {isDropdownOpen && (
        <motion.div
          className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {menuItems.map(({ href, text, Icon }, index) => (
            <motion.a
              key={text}
              href={href}
              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
              variants={itemVariants}
            >
              <Icon className="inline h-4 w-4 mr-2" />{text}
            </motion.a>
          ))}
          <motion.div variants={itemVariants}>
            <SignOutButton />
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}
