import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Sort() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Popularity");

  const [rotation, setRotation] = useState(0);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    // Toggle rotation by 180 degrees
    setRotation(rotation + 180);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setDropdownOpen(false); // Close the dropdown when a category is selected
    // Optionally, reset rotation to 0 when a category is selected
    setRotation(0);
  };

  const categories = ["Popularity", "New", "Trending"];

  return (
    <div className="relative">
      <button
        type="button"
        className="flex items-center justify-center  p-2 text-black border border-black text-sm"
        onClick={toggleDropdown}
      >
        Sort By: {selectedCategory}
        <motion.span
          style={{ display: "flex", transform: `rotate(${rotation}deg)` }}
          animate={{ rotate: rotation }}
        >
          <box-icon
            type="solid"
            name="chevron-down"
            color="black"
            className="mx-4"
          ></box-icon>
        </motion.span>
      </button>

      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            className="absolute z-30 p-2 bg-white shadow-lg rounded-md w-full"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={{
                animate: { transition: { staggerChildren: 0.1 } },
              }}
            >
              {categories.map((category, index) => (
                <motion.div
                  className="p-2 py-3 rounded-md hover:bg-blue-300 hover:text-white cursor-pointer"
                  key={index}
                  onClick={() => handleCategorySelect(category)}
                  variants={{
                    initial: { opacity: 0, y: -5 },
                    animate: { opacity: 1, y: 0 },
                    exit: { opacity: 0, y: -5 },
                  }}
                >
                  {category}
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
